<template>
    <b-modal :ref="modalRef" hide-footer @hidden="destroyModal" title="Réservation">
        <div class="text-center">
            <table>
                <tbody>
                <tr v-if="!isCurrentlyLoggedIn(reservation.utilisateur)">
                    <td class="text-right"><strong>Utilisateur :</strong></td>
                    <td class="text-left">
                        <user-name :user="reservation.utilisateur"/>
                    </td>
                </tr>
                <tr>
                    <td class="text-right"><strong>Dates :</strong></td>
                    <td class="text-left">{{ formatDates(reservation) }}</td>
                </tr>
                <tr>
                    <td class="text-right"><strong>Statut :</strong></td>
                    <td class="text-left">
                        <b-badge :variant="getBadgeColorStatut(reservation)"> {{ getTextStatut(reservation) }}</b-badge>
                    </td>
                </tr>
                </tbody>
            </table>

            <b-col class="text-left" lg="10" offset-lg="1">
                <div v-if="reservation.commentaireUtilisateur">
                    <hr/>
                    <h2>Commentaire utilisateur</h2>
                    <b-row>
                        <b-col cols="12">
                            <div class="ck-content" v-html="sanitize(reservation.commentaireUtilisateur)"></div>
                        </b-col>
                    </b-row>
                </div>

                <div v-if="reservation.commentaireBureau">
                    <hr/>
                    <h2>Commentaire du bureau</h2>
                    <b-row>
                        <b-col cols="12">
                            <div class="ck-content" v-html="sanitize(reservation.commentaireBureau)"></div>
                        </b-col>
                    </b-row>
                </div>

                <hr/>
            </b-col>

            <b-button @click="hideModal" variant="secondary">Ok</b-button>
        </div>
    </b-modal>
</template>

<script>
    const UserName = () => import('@/components/UserName');

    import demande               from '@/util/demande';
    import {isCurrentlyLoggedIn} from '@/util/auth';
    import {format2Dates}        from '@/util/date';
    import modalMixin            from '@/mixin/modalMixin';

    import {sanitize} from 'dompurify';

    export default {
        name: "DisplayReservationModal",
        components: {UserName},
        mixins: [modalMixin],
        props: {
            reservation: {
                type: Object,
                required: true
            }
        },
        data: () => ({
            modalRef: 'displayReservationModal'
        }),
        methods: {sanitize, format2Dates, isCurrentlyLoggedIn, ...demande}
    }
</script>

<style scoped>
    table {
        width: 100%;
    }

    td {
        padding-bottom: 0.5rem;
    }

    td.text-right {
        width: 33%;
    }

    td.text-left {
        padding-left: 10px;
    }
</style>